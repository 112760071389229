import {identity} from 'lodash';
import superagent from 'superagent/dist/superagent';
import {camelizeKeys} from 'humps';
import {AbortedRequest} from 'src/utils/errors';
import qs from 'qs';
import JWTService from 'src/services/JWTService';
import vars from 'src/utils/vars';
import shouldSendToCurrentOrigin from 'shared/shouldSendToCurrentOrigin';
import {handleUnauthorizedRequest} from 'src/actions/validateSession';

const pendingRequests = {};

/**
 * This flag informs req() that the user has been authenticated, such
 * that 401 responses from Olympus reset the token and force the user to the logout page.
 */
let authenticated = false;
export const setUserAuthenticated = (): void => {
  authenticated = true;
};

const isIp = (url: string): boolean => /\/\/(\d{1,3}\.){3}\d{1,3}/.test(url);
const isLocalhost = (url: string): boolean => url.startsWith('http://localhost') || isIp(url);

const isExternalDomain = (url: string): boolean =>
  !(url.startsWith('/') || url.includes('raise.me') || isLocalhost(url));

interface Opts<Response, TransformedResponse = Response> {
  url: string;
  method?: string;
  headers?: object;
  data?: object;

  responseTransformer?: (
    response: Response,
    textStatus?: string,
    jqXHR?: any
  ) => TransformedResponse;
  dataType?: string;
  cancelPendingRequests?: boolean;
}

export default function req<Result, TransformedResponse = Result>(
  opts: Opts<Result, TransformedResponse>
): Promise<TransformedResponse> {
  const {
    method = 'GET',
    headers = {},
    data = {},
    cancelPendingRequests = false,
    responseTransformer = identity,
  } = opts;

  if (!opts.url) {
    throw new Error('URL is undefined');
  }

  let url = opts.url;
  const baseUrl = url.split('?')[0];

  const sessionControllingPathname = shouldSendToCurrentOrigin(url);

  if (url.startsWith('/') && (vars.isSPA || !sessionControllingPathname)) {
    url = vars.API_GATEWAY + url;
  }

  const request = superagent(method.toUpperCase(), url).set(headers);

  if (
    (vars.isSPA || !sessionControllingPathname) &&
    !isExternalDomain(url) &&
    !headers['Authorization'] &&
    JWTService.userToken.value()
  ) {
    request.set('Authorization', `Bearer ${JWTService.userToken.value()}`);
  }

  switch (method.toLowerCase()) {
    case 'patch':
    case 'post':
    case 'put':
      request.send(JSON.stringify(data));
      request.set('Content-Type', 'application/json');

      break;
    default:
      request.query(qs.stringify(data, {arrayFormat: 'brackets'}));
      break;
  }

  return new Promise((resolve, reject) => {
    request
      .then((response: $TSFixMe) => {
        response = responseTransformer(response.body, response.xhr.statusText, response.xhr);
        if (sessionControllingPathname) {
          JWTService.updateTokenFromResponse(response);
        }

        resolve(response);
      })
      .catch((error: $TSFixMe) => {
        // if we cancel a pending request, error.code is ABORTED
        // this avoids triggering the promise rejection
        if (error.code === 'ABORTED') {
          reject(new AbortedRequest());
        } else if (error.status === 401 && /invalid user/.test(request.xhr.response)) {
          authenticated && handleUnauthorizedRequest();
          reject(error);
          return;
        }

        let errorResponse = error;
        if (error.response && error.response.body) {
          errorResponse = error.response.body;
        }
        reject(errorResponse);
      });

    if (cancelPendingRequests) {
      pendingRequests[baseUrl] = pendingRequests[baseUrl] || [];
      pendingRequests[baseUrl].forEach((r) => r.abort());
      pendingRequests[baseUrl] = [request];
    }
  });
}

export const rejectCamelizedErrors = (errors: $TSFixMe): Promise<Record<string, $TSFixMe>> =>
  Promise.reject(camelizeKeys(errors));

export const rejectGenericError = (responseJSON: $TSFixMe): Promise<Record<string, $TSFixMe>> => {
  if (!responseJSON) {
    return Promise.reject({
      _error: 'Oh snap! An error occurred. Please refresh the page and try again.',
    });
  }
  return Promise.reject(responseJSON);
};
